<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <!-- <el-form-item label="昵称">
          <el-input v-model="searchData.nickname" size="small" placeholder="请输入"></el-input>
        </el-form-item> -->
        <el-form-item label="手机号码">
          <el-input v-model.trim="searchData.mobile" size="small" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" size="small" icon="el-icon-search" @click="getList()">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!--        <el-button style="margin-bottom:20px;" type="primary" icon="el-icon-plus" size="small"-->
    <!--                   @click="$router.push({ name: 'AnnouncementAdd' })">-->
    <!--            添加-->
    <!--        </el-button>-->
    <el-divider></el-divider>
    <tp-table
      :isNeedSerialNumber="true"
      :tableData="list"
      :columns="columns"
      :totalNum="total"
      :current-page.sync="currentPage"
      :pageSize.sync="page_size"
    />
  </div>
</template>

<script>
import { getListAPI } from './api'

const columns = [
  // {
  //   label: '头像',
  //   prop: 'face',
  //   minWidth: '120',
  //   customRender(h, row) {
  //     return <el-image style="width: 60px; height: 60px;margin-top:5px;" src={row['face']} fit="fit"></el-image>
  //   }
  // },
  // {
  //   label: '姓名',
  //   prop: 'realname',
  //   minWidth: '120',
  //   customRender(h, row) {
  //     return <p>{row['realname'] || '-'}</p>
  //   }
  // },
  {
    label: '手机号码',
    prop: 'mobile',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['mobile'] || '-'}</p>
    }
  },

  {
    label: '注册时间',
    prop: 'created_at',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['created_at']}</p>
    }
  },
  // {
  //   label: '操作',
  //   fixed: 'right',
  //   minWidth: '100',
  //   customRender(h, row) {
  //     return (
  //       <div>
  //         <el-link
  //           type="primary"
  //           onClick={() =>
  //             this.$router.push({
  //               name: 'CustomerDetail',
  //               params: { id: row['id'] || undefined }
  //             })
  //           }
  //         >
  //           详情
  //         </el-link>
  //       </div>
  //     )
  //   }
  // }
]
export default {
  name: 'List',

  data() {
    return {
      columns,
      currentPage: 1,
      page_size: 10,
      total: 0,
      list: [],

      searchData: {
        nickname: '',
        mobile: ''
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  watch: {
    currentPage() {
      this.getList()
    },
    page_size() {
      this.getList()
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList() {
      let pageParams = { page: this.currentPage, page_size: this.page_size }
      let params = Object.assign(pageParams, this.searchData)
      const res = await getListAPI(params)
      this.list = res.data || []
      this.total = res.total || 0
    },

    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 35px;

        .el-input {
          width: 250px;
        }

        .el-date-editor {
          .el-range-separator {
            padding: 0;
          }

          .el-range-input {
            width: 35%;
          }
        }
      }
    }
  }

  .el-divider--horizontal {
    margin: 0 0 20px;
  }
}
</style>
